<template>
	<div class="ybd-view">
		<designHeader :isSaving="isSaving" :showBtn="showBtn" :hasEdit="hasEdit" :isJz="isJz"></designHeader>
		<div class="container">
			<designLeftNav :fromType='fromType'></designLeftNav>
			<div class="main">
				<div class="share_set">
					<el-tabs v-model="activeName">
						<el-tab-pane label="链接分享" name="linkShare">
							<div class="share-item link-share">
								<el-scrollbar>
									<div class="top">
										<p class="title">链接分享</p>
										<div class="link-box">
											<div class="copy">
												<el-input :value="shareUrl" ref="shareUrl" readonly></el-input>
												<el-button @click="copyShareUrl">复制链接</el-button>
											</div>
											<div class="share-btns">
												<el-popover placement="bottom" width="160" trigger="hover">
													<div class="qr-code">
														<vue-qr :text="shareUrl" :size="134" :margin="0"></vue-qr>
														<p class="tips" style="text-align: center;margin: 10px 0 0;color: #999;">扫码分享给好友</p>
													</div>
													<el-button slot="reference">
														<span class="iconfont icon-weixin"></span>
													</el-button>
												</el-popover>									
												<el-button>
													<a :href="'http://connect.qq.com/widget/shareqq/index.html?url=' + shareUrl" target="_blank">
														<span class="iconfont icon-qq"></span>
													</a>
												</el-button>
											</div>
										</div>
										<div>
											<el-button type="text" @click="publishAnswerPlaza(formId)">发布到答题广场</el-button>
										</div>
									</div>
									<div class="share-wechat">
										<p class="title">分享到微信</p>
										<div class="wechat-set">
											<div class="left">
												<p class="title">自定义图标</p>
												<div class="upload">
													<div class="btn">
														<el-upload class="upload-btn" action="/com/uplaod_modelimg.do" accept="image/*" :show-file-list="false" :before-upload="beforeImgUpload" :on-success="handleFormCustIcoSuccess">
															<img v-if="custIcoUrl" :src="custIcoUrl" class="img" />
															<i v-else class="el-icon-upload2"></i>
														</el-upload>
													</div>
													<div class="tips">
														<p class="p1">上传图片</p>
														<p class="p2">建议上传300x300px的jpg/png图片</p>
													</div>
												</div>
												<p class="title">微信分享标题</p>
												<div class="input-box">
													<el-input v-model="formTitle" placeholder="请输入分享标题"></el-input>
												</div>
												<p class="title">微信分享描述</p>
												<div class="input-box">
													<el-input placeholder="请输入分享描述" type="textarea" resize="none" v-model="formDescribe"></el-input>
												</div>
												<p class="c-tips">备注：据微信通知，项目中包含“分享到朋友圈”等内容将被禁用。</p>	
											</div>
											<div class="line"></div>
											<div class="right">
												<p class="title">好友分享预览</p>
												<div class="preview share-friend">
													<div class="cont">
														<div class="p-title ellipsis">{{formTitle}}</div>
														<div class="p-desc">
															<p class="txt">{{formDescribe}}</p>
															<img class="img" v-if="custIcoUrl" :src="custIcoUrl" />
															<img class="img" v-else src="@/assets/images/ruiyi.png" />
														</div>
													</div>
													<div class="portrait">
														<img src="@/assets/images/ruiyi.png" />
													</div>
												</div>
												<p class="title">朋友圈分享预览</p>
												<div class="preview share-circle">
													<div class="portrait">
														<img src="@/assets/images/ruiyi.png" />
														<p>微信昵称</p>
													</div>
													<div class="cont">
														<img v-if="custIcoUrl" :src="custIcoUrl" />
														<img v-else src="@/assets/images/ruiyi.png" />
														<div class="p-title ellipsis">{{formTitle}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="submit-btn">
										<el-button type="primary" @click="saveLinkShare">保存</el-button>
									</div>
								</el-scrollbar>
							</div>
						</el-tab-pane>
						<el-tab-pane label="二维码分享" name="qrcodeShare">
							<div class="share-item qrcode-share">
								<el-scrollbar>
									<div class="d-flex">
										<div class="style-f">
											<p class="f-tit">二维码</p>
											<p class="s-tit">用户可以通过扫码进行答题</p>
											<div class="qrcode-box">
												<vue-qr :text="shareUrl" :size="176" :margin="10"></vue-qr>
											</div>
											<el-button @click="downLoadQrCode">下载二维码</el-button>
										</div>
										<div class="style-s">
											<img class="recommend" src="@/assets/images/icon_recommend.png" alt="">
											<div class="main-cont">
												<p class="f-tit">二维码海报</p>
												<p class="s-tit">将二维码放入精美海报，帮助您提高收集效率</p>
												<div class="list">
													<div class="item">
														<img src="@/assets/images/img_poster01.jpg" />
													</div>
													<div class="item">
														<img src="@/assets/images/img_poster02.jpg" />
													</div>
													<div class="item">
														<img src="@/assets/images/img_poster03.jpg" />
													</div>
													<div class="item">
														<img src="@/assets/images/img_poster04.jpg" />
													</div>
												</div>
												<el-button @click="makeQrCodeFlag = true">制作二维码海报</el-button>
											</div>
										</div>
									</div>
								</el-scrollbar>
							</div>
						</el-tab-pane>
						<el-tab-pane label="邮件分享" name="emailShare">
							<div class="share-item email-share">
								<div class="top-btns">
									<el-button class="mailbox-set" @click="mailboxSettings = true">邮箱设置</el-button>
									<el-button class="view-email" @click="sentEmailsFlag = true">查看已发送邮件</el-button>
								</div>
								<el-steps :active="stepActive">
									<el-step title="添加收件人"></el-step>
									<el-step title="编辑内容"></el-step>
									<el-step title="发送成功"></el-step>
								</el-steps>
								<div class="step-cont">
									<div class="item add-receiver" v-show="stepActive == 1">
											<div class="tips">
												<p>您可以从左侧“通讯录”中批量添加，或在右侧手动输入收件人邮箱（或“邮箱,姓名”）。每次最多添加500个。</p>
												<el-button v-show="selectLinkManList.length > 0" @click="clearLinkmanList">全部清空</el-button>
											</div>
											<div class="linkman">
												<div class="left">
													<div class="title">
														<span>联系人</span>
														<el-button @click="linkmanListFlag = true">管理</el-button>
													</div>
													<div class="search-box">
														<el-input v-model="queryKey" placeholder="搜索联系人">
															<el-button slot="suffix" icon="el-icon-search" @click="queryLinkManList"></el-button>
														</el-input>
													</div>
													<div class="linkman-group">
														<el-scrollbar>
															<div class="list">
																<div class="item active" v-if="queryList.length > 0">													
																	<div class="linkman-list">
																		<div class="linkman-item" :class="{disabled: selectLinkManList.some(e=>e.id == item.id)}" v-for="item in queryList" :key="item.id">
																			<span>{{item.name}}</span>
																			<el-button type="text" @click="!selectLinkManList.some(e=>e.id == item.id) && selectLinkManList.push(item)">添加</el-button>
																		</div>
																	</div>
																</div>
																<div class="item">
																	<div class="label" @click="toggleActive($event)">
																		<i class="iconfont icon-zhankai1"></i>
																		<i class="iconfont icon-zhankai3"></i>
																		<span>全部联系人</span>
																	</div>
																	<div class="linkman-list" v-if="allLinkList.length > 0">
																		<div class="linkman-item" :class="{disabled: selectLinkManList.some(e=>e.id == item.id)}" v-for="item in allLinkList" :key="item.id">
																			<span>{{item.name}}</span>
																			<el-button type="text" @click="!selectLinkManList.some(e=>e.id == item.id) && selectLinkManList.push(item)">添加</el-button>
																		</div>
																	</div>
																	<div class="no-data" v-else>
																		<span>该分组下暂无数据哦！</span>
																	</div>
																</div>
																<div class="item" v-for="item in linkmanGroupList" :key="item.id">
																	<div class="label" @click="toggleActive($event)">
																		<i class="iconfont icon-zhankai1"></i>
																		<i class="iconfont icon-zhankai3"></i>
																		<span>{{item.name}}</span>
																	</div>
																	<div class="linkman-list" v-if="item.children && item.children.length > 0">
																		<div class="linkman-item" :class="{disabled: selectLinkManList.some(e=>e.id == i.id)}" v-for="i in item.children" :key="i.id">
																			<span>{{i.name}}</span>
																			<el-button type="text" @click="!selectLinkManList.some(e=>e.id == i.id) && selectLinkManList.push(i)">添加</el-button>
																		</div>
																	</div>
																	<div class="no-data" v-else>
																		<span>该分组下暂无数据哦！</span>
																	</div>
																</div>
																<div class="item">
																	<div class="label" @click="toggleActive($event)">
																		<i class="iconfont icon-zhankai1"></i>
																		<i class="iconfont icon-zhankai3"></i>
																		<span>未分组联系人</span>
																	</div>
																	<div class="linkman-list" v-if="noGroupLinkList.length > 0">
																		<div class="linkman-item" :class="{disabled: selectLinkManList.some(e=>e.id == item.id)}" v-for="item in noGroupLinkList" :key="item.id">
																			<span>{{item.name}}</span>
																			<el-button type="text" @click="!selectLinkManList.some(e=>e.id == item.id) && selectLinkManList.push(item)">添加</el-button>
																		</div>
																	</div>
																	<div class="no-data" v-else>
																		<span>该分组下暂无数据哦！</span>
																	</div>
																</div>
															</div>
														</el-scrollbar>
													</div>
												</div>
												<div class="right">
													<el-scrollbar>
														<div class="select-list" v-if="selectLinkManList.length > 0">
															<div class="item" v-for="(item,index) in selectLinkManList" :key="item.id">
																<div class="cont">
																	<span>{{item.email}}</span>
																	<span class="el-icon-minus"></span>
																	<span>{{item.name}}</span>
																</div>
																<el-button type="text" @click="selectLinkManList.splice(index,1)">删除</el-button>
															</div>
														</div>
													</el-scrollbar>
												</div>
											</div>
											<div class="foot">
												<div>已添加收件人数：{{selectLinkManList.length}}</div>
												<el-button type="primary" @click="editEmailCont">下一步</el-button>
											</div>
									</div>
									<div class="item edit-content" v-show="stepActive == 2">
										<div class="tips">
											<p>使用说明：“$[name]”表示收件人姓名，“$[surveylink]”表示测评地址</p>
										</div>
										<div class="subject">
											<span class="label">主题</span>
											<el-input v-model="subject"></el-input>
										</div>
										<div class="c-editor">
											<quill-editor ref="text" v-model="content" :options="editorOption" />
										</div>
										<div class="foot">
											<div class="f-cont">
												<!-- <div class="protocol">
													<el-checkbox v-model="agreeProtocol">我接受</el-checkbox>
													<el-button type="text" @click="shareProtocolFlag = true">《云表单邮件分享政策》</el-button>
												</div> -->
												<div class="btns">
													<el-button @click="stepActive = 1">上一步</el-button>
													<el-button type="primary" @click="sendSuccess">下一步</el-button>
												</div>
											</div>
										</div>
									</div>
									<div class="item send-success" v-show="stepActive == 3">
										<div class="">
											<el-result icon="success" title="发送成功！">
												<template slot="extra">
													<el-button type="primary" size="medium" @click="closeStep">关闭</el-button>
												</template>
											</el-result>
										</div>
									</div>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="页面" name="linkHtml">
							<div class="share-item linkhtml-share">
								<el-scrollbar>
									<div class="top">
										<p class="title">地址</p>
										<div class="link-box">
											<div class="copy">
												<el-input v-model="linkHtml" placeholder="链接请输入前缀如：http://或https://"></el-input>
												<el-button @click="saveLink">保存</el-button>
											</div>
										</div>
									</div>
								</el-scrollbar>
							</div>
						</el-tab-pane>
					</el-tabs>
					<!-- 制作二维码弹框 -->
					<div class="make-qrcode-pop" v-show="makeQrCodeFlag">
						<el-scrollbar>
							<div class="title">
								<span>二维码海报制作</span>
								<el-button @click="makeQrCodeFlag = false">返回</el-button>
							</div>
							<div class="poster-make">
								<div class="left">
									<div class="thumbnail_list">
										<div class="item" :class="['style'+i,{active: i == posterStyle}]" v-for="i in 5" :key="i" @click="posterStyle = i">								
											<div class="img"></div>
											<p>样式{{i}}</p>
										</div>
									</div>
								</div>
								<div class="center">
									<div class="c-title">
										<span>自定义背景图片</span>
										<el-checkbox v-model="isShowLogo">不显示Logo</el-checkbox>
									</div>
									<div class="upload-wrap">
										<div class="upload-item">
											<el-upload class="upload-btn" action="/com/uplaod_modelimg.do" accept="image/*" :show-file-list="false" :before-upload="beforeImgUpload" :on-success="handlePosterBgSuccess">
												<img v-if="posterBgUrl && posterBgRecommend.every(i=>i != posterBgUrl)" :src="posterBgUrl" class="img" />
												<div v-else class="upload-btn-ico">
													<i class="el-icon-upload2"></i>
													<p>上传图片</p>
												</div>
											</el-upload>
											<div class="tips">
												<p class="txt1">上传背景图片</p>
												<p class="txt2">建议尺寸 988x1752</p>
											</div>
										</div>
										<div class="upload-item" :class="{disabled: isShowLogo}">
											<el-upload class="upload-btn" action="/com/uplaod_modelimg.do" accept="image/*" :show-file-list="false" :before-upload="beforeImgUpload" :on-success="handlePosterLogoSuccess">
												<img v-if="posterLogoUrl" :src="posterLogoUrl" class="img" />
												<div v-else class="upload-btn-ico">
													<i class="el-icon-upload2"></i>
													<p>上传logo</p>
												</div>
											</el-upload>
											<div class="tips">
												<p class="txt1">上传logo</p>
												<p class="txt2">建议尺寸 264x60</p>
											</div>
										</div>
									</div>
									<div class="c-title">
										<span>推荐背景图片</span>
									</div>
									<div class="recommend-bg-list cl">
										<div class="item" :class="{active: item == posterBgUrl}" v-for="(item,index) in posterBgRecommend" :key="index" @click="posterBgUrl = item">
											<span class="bar">
												<i class="el-icon-check"></i>
											</span>
											<img :src="item" />
										</div>
									</div>
								</div>
								<div class="right">
									<div class="phone-box">
										<div class="phone-cover">
											<img src="@/assets/images/poster_phone.png" />
										</div>
										<div class="img-dom" ref="imageDom">
											<div class="cus-bg">
												<img v-if="posterBgUrl" :src="posterBgUrl" />
											</div>
											<div class="poster-mian" :class="'style-' + posterStyle">
												<div class="poster-code">
													<vue-qr v-if="isShowLogo" :text="shareUrl" :size="67" :margin="0"></vue-qr>
													<vue-qr v-else :text="shareUrl" :size="67" :margin="0" :logoSrc="posterLogoUrl" :logoMargin="3" :logoCornerRadius="0"></vue-qr>
													<p>长按图片扫码</p>
												</div>
												<div class="poster-title">{{formTitle}}</div>
											</div>
										</div>
									</div>
									<div class="btns">
										<el-button type="primary" @click="toImgDownLoad">下载</el-button>
									</div>
								</div>
							</div>
						</el-scrollbar>
					</div>
					<!-- 云表单邮件分享政策 -->
					<el-dialog class="share-protocol-pop" title="云表单邮件分享政策" :visible.sync="shareProtocolFlag">
						<div class="protocol-cont">
							<el-scrollbar>
							</el-scrollbar>
						</div>
					</el-dialog>
					<!-- 已发送邮件弹框 -->
					<div class="sent-emails-pop" v-show="sentEmailsFlag">
						<div class="title">
							<span>已发送邮件</span>
							<el-button @click="sentEmailsFlag = false">返回</el-button>
						</div>
						<div class="sent-list">
							<el-table :data="sentEmailsList" border>
								<el-table-column prop="id" label="序列" width="60" align="center"></el-table-column>
								<el-table-column prop="title" label="邮件标题"></el-table-column>
								<el-table-column prop="createTime" label="发送时间" width="200" align="center">
									<template slot-scope='scope'>
										{{scope.row.createTime | dateTimeFormat}}
									</template>
								</el-table-column>
								<el-table-column prop="address" label="发送方式" width="100" align="center">
									<template slot-scope='scope'>
										{{scope.row.emailType ==1?'表单代发':'收件人发送'}}
									</template>
								</el-table-column>
								<el-table-column prop="linkmanCount" label="收件人数" width="100" align="center"></el-table-column>
								<el-table-column label="操作" width="180" align="center">
									<template slot-scope='scope'>
										<el-button @click="delSentEmail(scope.row.id)" type="text" size="small">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange" :current-page="listParams.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="listParams.pageSize" layout="total, prev, pager, next, jumper" :total="count" prev-text="上一页" next-text="下一页" background></el-pagination>
						</div>
					</div>
					<!-- 邮箱设置弹框 -->
					<div class="mailbox-set-pop" v-show="mailboxSettings">
						<div class="title">
							<span>邮箱设置</span>
							<el-button @click="mailboxSettings = false">返回</el-button>
						</div>
						<div class="set-list">
							<el-form :model="ruleForm" :rules="rules" ref="mailFormName" label-width="200px">
								<el-form-item label="邮件服务器类型">
									<el-select v-model="ruleForm.mailServerType" placeholder="请选择活动区域">
										<el-option label="腾讯企业邮箱" :value="1"></el-option>
										<el-option label="腾讯QQ邮箱" :value="2"></el-option>
										<el-option label="网易163邮箱" :value="3"></el-option>
										<el-option label="自定义邮箱" :value="4"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="发件人姓名" prop="addresserName">
									<el-input v-model="ruleForm.addresserName" placeholder="请输入发件人姓名"></el-input>
								</el-form-item>
								<el-form-item label="收件人邮箱" prop="receiveMail">
									<el-input v-model="ruleForm.receiveMail" placeholder="请输入收件人邮箱"></el-input>
								</el-form-item>
								<el-form-item label="邮箱服务器" prop="mailboxServer">
									<el-input v-model="ruleForm.mailboxServer" placeholder="请输入邮箱服务器"></el-input>
								</el-form-item>
								<el-form-item label="邮件端口" prop="mailPort">
									<el-input v-model.number="ruleForm.mailPort" placeholder="请输入邮件端口"></el-input>
								</el-form-item>
								<el-form-item label="邮件用户名" prop="mailUsername">
									<el-input v-model="ruleForm.mailUsername" placeholder="请输入邮件用户名"></el-input>
								</el-form-item>
								<el-form-item label="邮件授权密码" prop="mailAuthPsd">
									<el-input v-model="ruleForm.mailAuthPsd" placeholder="请输入邮件授权密码"></el-input>
									<el-tooltip effect="dark" placement="right-start">
										<div class="mail-tips" slot="content">
											<p>腾讯企业邮箱：不要再用你开通邮箱时设置的密码了，要用提示你微信绑定时，自动给你设置的客户端专用密码；</p>
											<p>腾讯QQ邮箱：不能使用邮箱登录密码，需要在邮箱中开启POP3/SMTP服务，开启后邮箱会给你一个授权码；</p>
											<p>网易163邮箱：不能使用邮箱登录密码，需要在邮箱中开启POP3/SMTP服务（默认是开启的），开启后并在授权密码管理处设置授权码。</p>
										</div>
										<el-button class="el-icon-info"></el-button>
									</el-tooltip>
								</el-form-item>
								<el-form-item label="邮件编码" prop="mailCode">
									<el-input v-model="ruleForm.mailCode" placeholder="请输入邮件编码如：utf-8"></el-input>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="submitMailForm('mailFormName')">保存</el-button>
									<el-button type="primary" @click="testMailForm('mailFormName')">测试</el-button>
								</el-form-item>
							</el-form>
						</div>
					</div>
					<!-- 联系人列表弹框 -->
					<div class="linkman-list-pop" v-show="linkmanListFlag">
						<div class="title">
							<span>联系人<i class="num">{{linkmanCount}}</i></span>
							<el-button @click="linkmanListFlag = false">返回</el-button>
						</div>
						<div class="linkman-main">
							<div class="head">
								<div class="search">
									<el-select v-model="filterLinkmanGroup" @change="filterLinkmanFunc($event)">
										<el-option label="全部联系人" value=""></el-option>
										<el-option v-for="item in linkmanGroupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
										<el-option label="未分组联系人" value="0"></el-option>
									</el-select>
									<div class="search-box">
										<el-input v-model="searchLinkmanKey" placeholder="搜索联系人">
											<el-button slot="suffix" icon="el-icon-search" @click="searchLinkmanFun"></el-button>
										</el-input>
									</div>
								</div>
								<div class="btns">
									<el-button class="el-dropdown-link" @click="linkGroupSetFlag = true">
										<i class="el-icon-folder"></i>
										<span>分组设置</span>
									</el-button>
									<el-button class="el-dropdown-link" @click="addLinkmanFunc">
										<i class="iconfont icon-tianjialianxiren"></i>
										<span>添加联系人</span>
									</el-button>
									<!-- <el-dropdown trigger="click" @command="handleCommand">
										<el-button class="el-dropdown-link">
											<i class="iconfont icon-tianjialianxiren"></i>
											<span>添加联系人</span>
											<i class="el-icon-arrow-down el-icon--right"></i>
										</el-button>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item>从excel文件导入</el-dropdown-item>
											<el-dropdown-item command="2">手动添加</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
									<el-button>
										<i class="iconfont icon-daochu"></i>
										<span>导出</span>
									</el-button> -->
								</div>
							</div>
							<div class="table-list">
								<el-table :data="linkmanList" border>
									<el-table-column prop="name" label="姓名" width="100"></el-table-column>
									<el-table-column prop="email" label="邮箱"></el-table-column>
									<el-table-column prop="phone" label="手机" width="150" align="center"></el-table-column>
									<el-table-column prop="groupName" label="群组"></el-table-column>
									<el-table-column prop="company" label="公司"></el-table-column>
									<el-table-column label="操作" width="100" align="center">
										<template slot-scope="scope">
											<div class="operate">
												<el-tooltip effect="dark" content="编辑" placement="top-start">
													<el-button icon="el-icon-edit-outline" @click="editLinkmanItem(scope.row)"></el-button>
												</el-tooltip>
												<el-tooltip effect="dark" content="设置组" placement="top-start">
													<el-button icon="el-icon-folder-checked" @click="setLinkmanItem(scope.row)"></el-button>
												</el-tooltip>
												<el-tooltip effect="dark" content="删除" placement="top-start">
													<el-button icon="el-icon-delete" @click="deleteLinkmanItem(scope.row)"></el-button>
												</el-tooltip>
											</div>
										</template>
									</el-table-column>
								</el-table>
								<el-pagination @size-change="pageSizeLinkman" @current-change="pageCurrentLinkman" :current-page="linkman.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="linkman.pageSize" layout="total, prev, pager, next, jumper" :total="linkmanCount" prev-text="上一页" next-text="下一页" background></el-pagination>
							</div>
						</div>
					</div>
					<!-- 添加、修改联系人 -->
					<div class="add-linkman-pop" v-show="addLinkmanFlag">
						<div class="title">
							<span>{{isAddLinkman?'添加联系人':'编辑联系人'}}</span>
							<el-button @click="addLinkmanFlag = false">返回</el-button>
						</div>
						<div class="add-linkman">
							<el-form :inline="true" :model="currentLinkman" :rules="linkmanRules" ref="linkManFormName" label-width="100px">
								<div class="form-item">
									<el-form-item label="姓名" prop="name">
										<el-input v-model="currentLinkman.name" placeholder="请输入姓名"></el-input>
									</el-form-item>
									<el-form-item label="性别" prop="sex">
										<el-select v-model="currentLinkman.sex" placeholder="请选择">
											<el-option label="男" :value="0"></el-option>
											<el-option label="女" :value="1"></el-option>
										</el-select>
									</el-form-item>						
								</div>
								<div class="form-item">
									<el-form-item label="生日" prop="birthday">
										<el-date-picker v-model="currentLinkman.birthday" type="date" placeholder="请选择日期" value-format="yyyy-MM-dd"></el-date-picker>
									</el-form-item>
									<el-form-item label="手机" prop="phone">
										<el-input v-model="currentLinkman.phone" placeholder="请输入手机号码" maxlength="11"></el-input>
									</el-form-item>
								</div>
								<div class="form-item">
									<el-form-item label="公司" prop="company">
										<el-input v-model="currentLinkman.company" placeholder="请输入公司"></el-input>
									</el-form-item>
									<el-form-item label="职位" prop="site">
										<el-input v-model="currentLinkman.site" placeholder="请输入职位"></el-input>
									</el-form-item>
								</div>
								<div class="form-item">
									<el-form-item label="邮箱" prop="email">
										<el-input v-model="currentLinkman.email" placeholder="请输入邮箱地址"></el-input>
									</el-form-item>
									<el-form-item label="固话" prop="tel">
										<el-input v-model="currentLinkman.tel" placeholder="请输入职位"></el-input>
									</el-form-item>
								</div>
								<div class="form-item">
									<el-form-item label="地址">
										<el-cascader :options="options" placeholder="请选择地址" v-model="locationSelectedOptions" separator="" :clearable="true"></el-cascader>
										<el-input type="textarea" v-model="currentLinkman.position" placeholder="请输入详细地址" resize="none" rows="4"></el-input>
									</el-form-item>
								</div>
								<div class="form-item">
									<el-form-item label="备注" prop="remark">
										<el-input type="textarea" v-model="currentLinkman.remark" placeholder="请输入备注" resize="none" rows="4"></el-input>
									</el-form-item>
								</div>
								<div class="form-item form-btn">
									<el-form-item>
										<el-button v-if="isAddLinkman" type="primary" @click="submitAddLinkman('linkManFormName')">保存</el-button>
										<el-button v-else type="primary" @click="submitEditLinkman('linkManFormName')">保存</el-button>
									</el-form-item>
								</div>
							</el-form>
						</div>
					</div>
					<!-- 设置联系人分组 -->
					<el-dialog class="set-linkgroup-pop" title="设置分组" :visible.sync="setLinkGroupFlag">
						<div class="linkman-groups">
							<el-checkbox-group v-if="linkmanGroupList.length > 0" v-model="linkmanGruopArr">
								<el-checkbox v-for="(item,index) in linkmanGroupList" :key="index" :label="item.name"></el-checkbox>
							</el-checkbox-group>
							<el-empty v-else description="暂时还没有分组哦！"></el-empty>
						</div>
						<div slot="footer" class="dialog-footer">
							<el-button @click="setLinkGroupFlag = false">关 闭</el-button>
							<el-button @click="saveLinkmanGroup" type="primary">保 存</el-button>
						</div>
					</el-dialog>
					<!-- 分组设置 -->
					<el-dialog class="linkgroup-set-pop" title="分组设置" :visible.sync="linkGroupSetFlag">
						<div class="add-btn">
							<el-button type="primary" @click="newLinkgroupName = '',addLinkGroupFlag = true">添加分组</el-button>
						</div>
						<div class="list" v-if="linkmanGroupList.length > 0">
							<el-scrollbar>
								<div class="item" v-for="(item,index) in linkmanGroupList" :key="index">
									<el-input v-if="item.isEdit" v-model="item.name" maxlength="10"></el-input>
									<span v-else>{{item.name}}</span>
									<div class="btn" v-if="!item.isEdit">
										<el-tooltip effect="dark" content="编辑" placement="top">
											<el-button class="el-icon-edit-outline" @click="editLinkgroupName(item)"></el-button>
										</el-tooltip>
										<el-tooltip effect="dark" content="删除" placement="top">
											<el-button class="el-icon-delete" @click="deleteLinkgroup(item)"></el-button>
										</el-tooltip>
									</div>
									<div v-else class="sure-btn">
										<el-button type="primary" @click="sureEditLinkgroup(item)">确定</el-button>
									</div>
								</div>
							</el-scrollbar>
						</div>
						<div v-else>
							<el-empty description="暂时还没有分组哦！"></el-empty>
						</div>
						<div slot="footer" class="dialog-footer">
							<el-button @click="linkGroupSetFlag = false">关 闭</el-button>
						</div>
						<el-dialog class="add-link-group" title="添加分组" :visible.sync="addLinkGroupFlag" append-to-body>
							<div class="add-input">
								<el-input placeholder="请输入分组名称" v-model="newLinkgroupName" maxlength="10"></el-input>
							</div>
							<div slot="footer" class="dialog-footer">
								<el-button @click="addLinkGroupFlag = false">取 消</el-button>
								<el-button @click="sureAddLinkgroup" type="primary">保 存</el-button>
							</div>
						</el-dialog>
					</el-dialog>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import $ from "jquery";
import designHeader from "@/components/designHeader.vue";
import designLeftNav from "@/components/designLeftNav.vue";
import { get,post } from "/src/services/ajax_ucenter.js";
import vueQr from 'vue-qr';
import html2canvas from 'html2canvas';
import { quillEditor } from 'vue-quill-editor';
import { regionData,CodeToText,TextToCode } from 'element-china-area-data'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
	components: {
		designHeader,
		designLeftNav,
		vueQr,
		quillEditor
	},
	data(){
		return {
			activeName: 'linkShare',
			formId: '',
			formToken: sessionStorage.getItem('token')?sessionStorage.getItem('token'):'',
			fromType: '',
			showBtn:false,
			hasEdit:0,
			isSaving:false,
			isJz: sessionStorage.getItem('isJz')?true:false,
			stepActive: 1,
			shareUrl: '',
			formTitle: '表单名称',
			formDescribe: '',
			custIcoUrl: '',
			makeQrCodeFlag: false,
			id: '',
			posterStyle: 1,
			isShowLogo: false,
			posterBgUrl: require("@/assets/images/poster_bg01.jpg"),
			posterLogoUrl: '',
			posterBgRecommend: [
				require("@/assets/images/poster_bg01.jpg"),
				require("@/assets/images/poster_bg02.jpg"),
				require("@/assets/images/poster_bg03.jpg"),
				require("@/assets/images/poster_bg04.jpg"),
				require("@/assets/images/poster_bg05.jpg"),
				require("@/assets/images/poster_bg06.jpg"),
				require("@/assets/images/poster_bg07.jpg"),
				require("@/assets/images/poster_bg08.jpg"),
				require("@/assets/images/poster_bg09.jpg"),
				require("@/assets/images/poster_bg10.jpg"),
			],
			linkman:{
				pageNo:1,
				pageSize:10
			},
			linkmanCount:0,
			linkmanGroupList: [],
			allLinkList: [],
			noGroupLinkList: [],
			selectLinkManList: [],
			queryKey: '',
			queryList: [],
			subject: '',
			content: '$[name]你好，<br/><br/>我刚刚通过云表单制作了一份问卷“示例问卷”，需要你来回答，你的意见非常重要，请速帮忙回答哦。 $[surveylink]',
			editorOption: {
				modules: {
					toolbar: [
						['bold', 'italic', 'underline', 'strike'],
						[{ 'list': 'ordered'}, { 'list': 'bullet' }],
						[{ 'script': 'sub'}, { 'script': 'super' }],
						[{ 'indent': '-1'}, { 'indent': '+1' }],
						[{ 'direction': 'rtl' }],
						[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
						[{ 'color': [] }, { 'background': [] }], 
						[{ 'align': [] }],
						['clean'] ,
						['link', 'image']
					]
				}
			},
			agreeProtocol: false,
			shareProtocolFlag: false,
			sentEmailsFlag: false,
			mailboxSettings: false,
			ruleForm: {
				mailServerType: 1,
				addresserName: '',
				receiveMail:'',
				mailboxServer: '',
				mailPort: '',
				mailUsername: '',
				mailAuthPsd: '',
				mailCode: '',
				id: '',
				userid: '',
				testInput:0
			},
			rules: {
				addresserName: [
					{ required: true, message: '请输入发件人姓名', trigger: 'blur' },
				],
				receiveMail: [
					{ required: true, message: '请输入收件人邮箱', trigger: 'blur' },
				],
				mailboxServer: [
					{ required: true, message: '请输入邮箱服务器', trigger: 'blur' }
				],
				mailPort: [
					{ required: true, message: '请输入邮件端口', trigger: 'blur'},
					{ type: 'number', message: '邮件端口必须为数字', trigger: 'blur'}
				],
				mailUsername: [
					{ required: true, message: '请输入邮件用户名', trigger: 'blur' }
				],
				mailAuthPsd: [
					{ required: true, message: '请输入邮件授权密码', trigger: 'blur' }
				],
				mailCode: [
					{ required: true, message: '请输入邮件编码', trigger: 'blur' }
				]
			},
			sentEmailsList: [],
			listParams:{
				pageNo:1,
				pageSize:10
			},
			count: 0,
			linkmanListFlag: false,
			linkmanList: [],
			filterLinkmanGroup: '',
			searchLinkmanKey: '',
			addLinkmanFlag: false,
			isAddLinkman: true,
			currentLinkman: {
				name: '',
				birthday: '',
				company: '',
				phone: '',
				email: '',
				site: '',
				sex: 0,
				tel: '',
				province: '',
				city: '',
				prefecture: '',
				position: '',
				remark: '',
				id: '',
				userid: '',
				createTime: '',
				updateTime: '',
				groupName: ''
			},
			linkmanRules: {
				name: [
					{ required: true, message: '请输入姓名', trigger: 'blur' },
				],
				phone: [
					{ required: true, message: '请输入手机号', trigger: 'blur' }
				],
				email: [
					{ required: true, message: '请输入邮箱', trigger: 'blur' },
					{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
				],
			},
			options: regionData ,
			locationSelectedOptions: [],
			linkGroupSetFlag: false,
			addLinkGroupFlag: false,
			newLinkgroupName: '',
			setLinkGroupFlag: false,
			currentLinkmanGruop: {},
			linkmanGruopArr: [],
			linkHtml:''
		}
	},
	watch: {
		"ruleForm.mailServerType": {
			handler(val) {
				switch (val) {
					case 1:
						this.ruleForm.mailboxServer = 'smtp.exmail.qq.com';
						this.ruleForm.mailPort = 587;
						break;
					case 2:
						this.ruleForm.mailboxServer = 'smtp.qq.com';
						this.ruleForm.mailPort = 587;
						break;
					case 3:
						this.ruleForm.mailboxServer = 'smtp.163.com';
						this.ruleForm.mailPort = 25;
						break;
					case 4:
						this.ruleForm.mailboxServer = '';
						this.ruleForm.mailPort = '';
						break;
					default:
						break;
				}
			},
			deep: true,
			immediate: true,
		},
		"locationSelectedOptions": {
			handler(val) {
				this.currentLinkman.province = CodeToText[val[0]];
				this.currentLinkman.city = CodeToText[val[1]];
				this.currentLinkman.prefecture = CodeToText[val[2]];
			},
			deep: true,
			immediate: true,
		}
	},
	mounted(){
		this.fromType = this.$route.query.fromType?this.$route.query.fromType:sessionStorage.getItem('fromType')?sessionStorage.getItem('fromType'):'';
		this.formId = this.$route.query.formId?this.$route.query.formId:sessionStorage.getItem('formId')?sessionStorage.getItem('formId'):'';
		sessionStorage.setItem('formId',this.formId);
		sessionStorage.setItem('fromType',this.fromType)
		if(this.formId){
			
			if(this.fromType == "form"){
				this.shareUrl = window.location.origin + '/detail?formId=' + this.formId;
			}else if(this.fromType == "exam"){
				this.shareUrl = window.location.origin + '/exam_detail?formId=' + this.formId;
			}else{
				this.shareUrl = window.location.origin + '/ques_detail?formId=' + this.formId;
			}
			this.getFormShare();
			this.getEmailContactList();
			this.getNoGroupLinkList();
			this.getMailSetData();
			this.getSendMailList();
		}else{
			console.log('去登录');
		}
	},
	methods: {
		// 发布
		getFormPublish(){
			const _this = this;
			post('/formadmin/form_publish.jhtml',{formId: _this.formId}).then(res => {
				if(res.code == 200){
					this.$message({
						type: 'success',
						message: '发布成功！',
						duration: 500
					});
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 页面中提交后跳转链接
		saveLink(){
			const _this = this;
			if(this.linkHtml == ''){
				_this.$message.error('跳转链接不能为空！');
				return false;
			}else{
				let regx = /(http|https|ftp):/;
				if(!(regx.test(this.linkHtml) )){
					_this.$message.error('请输入正确的地址！');
					return false;
				}
			}
			post('/formadmin/link_html_submit.jhtml',{formId: _this.formId,htmlUrl:this.linkHtml}).then(res => {
				if(res.code == 200){
					this.$message({
						type: 'success',
						message: '保存成功！',
						duration: 500
					});
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取表单分享设置
		getFormShare(){
			const _this = this;
			get('/formadmin/link_share.jhtml',{formId: _this.formId}).then(res => {
				if(res.code == 200){
					const data = res.data.wxEntity?res.data.wxEntity:{};
					_this.custIcoUrl = data.imgUrl?data.imgUrl:'';
					_this.formTitle = data.wxTitle?data.wxTitle:'表单名称';
					_this.formDescribe = data.wxRemark?data.wxRemark:'';
					_this.id = data._id?data._id:'';
					const entity = res.data.entity?res.data.entity:{}
					if(entity && entity.status != 2){
						this.getFormPublish();
					}
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 复制链接
		copyShareUrl(){
			this.$refs.shareUrl.select();
			document.execCommand('copy');
			this.$message({
				type: 'success',
				message: '复制成功！',
				duration: 500
			});
		},
		// 发布到答题广场
		publishAnswerPlaza(formId){
			const _this = this;
			_this.$confirm('1、答题广场为免费服务，但项目需要经过审核才能发布到广场。\n 2、所有广场项目均会被公开发布，且默认同意可以被他人引用。\n 3、广场面向人群较广，不适合所有项目，平台不保证答题质量。', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/publish_answer_plaza.jhtml',{formId}).then(res => {
					if(res.code == 200){
						_this.$message({
							type: 'success',
							message: '发布成功,正在等待审核!',
							duration: 500,
							onClose(){
								// _this.ssokey = sessionStorage.getItem('ssokey')
								// window.location.href = 'http://bd.ruiyi126.com/dtgc/?formId='+formId + '&ssokey='+_this.ssokey
								_this.getAuthToken()
							}
						});
						
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
				
			}).catch(() => {});
		},
		getAuthToken(){
			const _this = this;
			get('/formadmin/getFormDesignAuthToken.jhtml',{}).then(res => {
				if(res.code == 200){
					let identityType = res.data.identityType;
					let authToken = res.data.authToken;
					post('/com/auth.api',{identityType,authToken}).then(res => {
						if(res.code == 200){
							// sessionStorage.setItem('form_token',res.data.form_token)
							window.location.href = 'http://bd.ruiyi126.com/dtgc/?token='+res.data.form_token
							
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
					// _this.getPage(formId,res.data.identityType,res.data.authToken,viewType)
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 上传前校验文件大小 不能超过5M
		beforeImgUpload(file){
			const isLt5M = file.size / 1024 / 1024 < 5;
			if (!isLt5M) {
				this.$message.error("大小不能超过 5MB!");
			}
			return isLt5M;
		},
		// 上传自定义图标成功回调
		handleFormCustIcoSuccess(file){
			if(file.code == 200){
				this.custIcoUrl = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 上传海报背景成功回调
		handlePosterBgSuccess(file){
			if(file.code == 200){
				this.posterBgUrl = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 上传海报logo成功回调
		handlePosterLogoSuccess(file){
			if(file.code == 200){
				this.posterLogoUrl = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 保存链接分享
		saveLinkShare(){
			const _this = this;
			if(_this.formTitle == ''){
				_this.$message.error('请输入表单标题！');
				return false;
			}
			let jsonData = {
				formId: _this.formId,
				imgUrl: _this.custIcoUrl,
				wxTitle:  _this.formTitle,
				wxRemark:  _this.formDescribe,
			}
			if(_this.id){
				jsonData._id = _this.id;
			}
			post('/formadmin/wxFormat/saveWxFormat.jhtml',jsonData).then(res => {
				if(res.code == 200){
					this.$message({
						type: 'success',
						message: '保存成功！',
						duration: 500,
						onClose(){
							_this.getFormShare();
						}
					});
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 下载二维码
		downLoadQrCode(){
			let a = document.createElement('a');
			a.href = $(".qrcode-box img").attr("src");
			a.download = '二维码' + new Date().getTime();
			a.click();
			this.$message({
				message: "正在进行下载保存",
				duration: 1000,
				type: 'success'
			})
		},
		// 生成并下载海报二维码
		toImgDownLoad(){
			let img = document.createElement("img");
			html2canvas(this.$refs.imageDom,{allowTaint:false, useCORS:true,}).then(canvas => {
				img.src = canvas.toDataURL("image/png");
			}).then(() =>{
				let	a = document.createElement("a");
				a.href = img.src;
				a.download = "poster_" + new Date().getTime();
				a.click();
			});
		},
		// 获取邮件联系人组列表
		getEmailContactList(param){
			const _this = this;
			if(param){
				get('/formadmin/linkman/linkman_list.jhtml',param).then(res => {
					if(res.code == 200){
						// _this.linkmanGroupList = res.data.linkmanGroupList;
						// _this.allLinkList = res.data.date;
						_this.linkmanList = res.data.date;
						_this.linkmanCount = res.data.count;
						if(_this.linkmanGroupList.length > 0){
							_this.getLinkmanList(_this.linkmanGroupList);
						}
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}else{
				get('/formadmin/linkman/linkman_list.jhtml',{}).then(res => {
					if(res.code == 200){
						_this.linkmanGroupList = res.data.linkmanGroupList;
						_this.allLinkList = res.data.date;
						_this.linkmanList = res.data.date;
						_this.linkmanCount = res.data.count;
						if(_this.linkmanGroupList.length > 0){
							_this.getLinkmanList(_this.linkmanGroupList);
						}
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}
		},
		// 获取邮件联系人列表
		getLinkmanList(arr){
			const _this = this;
			arr.forEach(i=>{
				get('/formadmin/linkman/linkman_list.jhtml',{linkmanGroup: i.id}).then(res => {
					if(res.code == 200){
						_this.$set(i,'children',res.data.date)
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			})
		},
		// 获取邮件未分组联系人列表
		getNoGroupLinkList(){
			const _this = this;
			get('/formadmin/linkman/linkman_list.jhtml',{linkmanGroup: '0'}).then(res => {
				if(res.code == 200){
					_this.noGroupLinkList = res.data.date;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 搜索联系人
		queryLinkManList(){
			const _this = this;
			if(!_this.queryKey){
				_this.queryList = [];
				this.$message.error('请输入联系人！');
				return false;
			}else{
				post('/formadmin/linkman/findLinkmanName.jhtml',{name: _this.queryKey}).then(res => {
					if(res.code == 200){
						_this.queryKey = '';
						_this.queryList = res.data.list;
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}
		},
		// 联系人列表 - 搜索联系人
		searchLinkmanFun(){
			const _this = this;
			if(!_this.searchLinkmanKey){
				get('/formadmin/linkman/linkman_list.jhtml',{linkmanGroup: ''}).then(res => {
					if(res.code == 200){
						_this.linkmanList = res.data.date;
						_this.linkmanCount = res.data.count;
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}else{
				post('/formadmin/linkman/findLinkmanName.jhtml',{name: _this.searchLinkmanKey}).then(res => {
					if(res.code == 200){
						_this.linkmanList = res.data.list;
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}
		},
		// 联系人列表分页
		pageCurrentLinkman(parm){
			this.linkman.pageNo = parm;
			this.getEmailContactList(this.linkman);
		},
		pageSizeLinkman(parm){
			this.linkman.pageSize = parm;
			this.getEmailContactList(this.linkman);
		},
		// 展开、收起
		toggleActive(e){
			$(e.target).closest('.item').toggleClass('active');
		},
		// 清空联系人
		clearLinkmanList(){
			this.selectLinkManList = [];
		},
		// 下一步 - 编辑内容
		editEmailCont(){
			if(this.selectLinkManList.length == 0){
				this.$message.error('请选择收件人！');
			}else{
				this.stepActive = 2;
			}
		},
		// 下一步 - 发送成功
		sendSuccess(){
			const _this = this;
			if(!_this.subject){
				_this.$message.error('请输入主题！');
				return false;
			}
			if(!_this.content){
				_this.$message.error('请输入内容！');
				return false;
			}
			// if(!this.agreeProtocol){
			// 	this.$message.error('请选择“我接受”！');
			// 	return false;
			// }
			let jsonData = {
				formId: _this.formId,
				linkman: _this.selectLinkManList.map(i=>i.id).join(','),
				text: _this.content,
				addresser: _this.ruleForm.addresserName,
				email: _this.ruleForm.mailboxServer,
				title: _this.subject,
				emailType: _this.ruleForm.mailServerType
			}
			post('/formadmin/email_text_submit.jhtml',jsonData).then(res => {
				if(res.code == 200){
					this.stepActive = 3;
					_this.getSendMailList()
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 关闭
		closeStep(){
			this.stepActive = 1;
			this.selectLinkManList = [];
			this.subject = '';
			this.content = '$[name]你好，<br/><br/>我刚刚通过云表单制作了一份问卷“示例问卷”，需要你来回答，你的意见非常重要，请速帮忙回答哦。 $[surveylink]';
		},
		// 获取邮箱设置
		getMailSetData(){
			const _this = this;
			get('/formadmin/emailSet/email_set_list.jhtml',{formId: _this.formId}).then(res => {
				if(res.code == 200){
					if(res.data.entity){
						let entity = res.data.entity;
						_this.ruleForm.mailServerType = entity.emailType;
						_this.ruleForm.receiveMail = entity.receiveMail;
						_this.ruleForm.addresserName = entity.addresser;
						_this.ruleForm.mailboxServer = entity.emailServer;
						_this.ruleForm.mailPort = entity.port;
						_this.ruleForm.mailUsername = entity.emailName;
						_this.ruleForm.mailAuthPsd = entity.password;
						_this.ruleForm.mailCode = entity.coding;
						_this.ruleForm.id = entity.id;
						_this.ruleForm.userid = entity.userid;
					}
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取邮箱发送记录
		getSendMailList(){
			const _this = this;
			let params = {
				formId: _this.formId,
				..._this.listParams
			}
			get('/formadmin/email_record.jhtml',params).then(res => {
				if(res.code == 200){
					_this.sentEmailsList = res.data.date;
					_this.count = res.data.count;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 邮箱发送记录分页
		pageCurrentChange(parm){
			this.listParams.pageNo = parm;
			this.getSendMailList();
		},
		pageSizeChange(parm){
			this.listParams.pageSize = parm;
			this.getSendMailList();
		},
		// 删除邮箱发送记录
		delSentEmail(id){
			const _this = this;
			_this.$confirm('此操作将永久删除发送记录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/delete_email_record.jhtml',{id}).then(res => {
					if(res.code == 200){
						_this.$message({
							type: 'success',
							message: '删除成功!',
							duration: 500,
							onClose(){
								_this.getSendMailList();
							}
						});
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {});
		},
		// 保存邮箱设置
		submitMailForm(formName){
			const _this = this;
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let jsonData = {
						id: _this.ruleForm.id,
						userid: _this.ruleForm.userid,
						emailType: _this.ruleForm.mailServerType,
						receiveMail:_this.ruleForm.receiveMail,
						addresser: _this.ruleForm.addresserName,
						emailServer: _this.ruleForm.mailboxServer,
						port: _this.ruleForm.mailPort,
						emailName: _this.ruleForm.mailUsername,
						password: _this.ruleForm.mailAuthPsd,
						coding: _this.ruleForm.mailCode,
						testInput: _this.ruleForm.testInput,
					}
					post('/formadmin/emailSet/save_emailSet.jhtml',jsonData).then(res => {
						if(res.code == 200){
							this.$message({
								type: 'success',
								message: '保存成功！',
								duration: 500,
								onClose(){
									_this.getMailSetData();
								}
							});
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				} else {
					return false;
				}
			});
		},
		// 保存添加联系人
		submitAddLinkman(formName){
			const _this = this;
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let jsonData = {..._this.currentLinkman,linkmanName:_this.currentLinkman.name};
					post('/formadmin/linkman/linkman_save.jhtml',jsonData).then(res => {
						if(res.code == 200){
							this.$message({
								type: 'success',
								message: '保存成功！',
								duration: 500,
								onClose(){
									_this.addLinkmanFlag = false;
									_this.getEmailContactList();
									_this.getNoGroupLinkList();
								}
							});
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				} else {
					return false;
				}
			});
		},
		// 保存编辑联系人
		submitEditLinkman(formName){
			const _this = this;
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let jsonData = {..._this.currentLinkman,linkmanName:_this.currentLinkman.name,linkmanId:_this.currentLinkman.id};
					post('/formadmin/linkman/linkman_update.jhtml',jsonData).then(res => {
						if(res.code == 200){
							this.$message({
								type: 'success',
								message: '保存成功！',
								duration: 500,
								onClose(){
									_this.addLinkmanFlag = false;
									_this.getEmailContactList();
									_this.getNoGroupLinkList();
								}
							});
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				} else {
					return false;
				}
			});
		},
		// 测试邮箱设置
		testMailForm(formName){
			const _this = this;
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let jsonData = {
						id: _this.ruleForm.id,
						userid: _this.ruleForm.userid,
						emailType: _this.ruleForm.mailServerType,
						receiveMail:_this.ruleForm.receiveMail,
						addresser: _this.ruleForm.addresserName,
						emailServer: _this.ruleForm.mailboxServer,
						port: _this.ruleForm.mailPort,
						emailName: _this.ruleForm.mailUsername,
						password: _this.ruleForm.mailAuthPsd,
						coding: _this.ruleForm.mailCode
					}
					post('/formadmin/emailSet/test_emailSet.jhtml',jsonData).then(res => {
						if(res.code == 200){
							this.$message({
								type: 'success',
								message: '测试成功！',
								duration: 500,
							});
							_this.ruleForm.testInput = 1;
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				} else {
					return false;
				}
			});
		},
		// 联系人组筛选
		filterLinkmanFunc(e){
			const _this = this;
			get('/formadmin/linkman/linkman_list.jhtml',{linkmanGroup: e}).then(res => {
				if(res.code == 200){
					_this.linkmanList = res.data.date
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		handleCommand(command){
			if(command == 2){ 
				this.addLinkmanFunc();
			}
		},
		// 添加联系人
		addLinkmanFunc(){
			this.addLinkmanFlag = true;
			this.isAddLinkman = true;
			this.currentLinkman = {
				name: '',
				birthday: '',
				company: '',
				phone: '',
				email: '',
				site: '',
				sex: 0,
				tel: '',
				province: '',
				city: '',
				prefecture: '',
				position: '',
				remark: '',
				id: '',
				userid: '',
				createTime: '',
				updateTime: '',
				groupName: ''
			}
		},
		// 编辑联系人
		editLinkmanItem(obj){
			this.currentLinkman = obj;
			if(this.currentLinkman.province){
				let a =	TextToCode[this.currentLinkman.province].code;
				let b =	TextToCode[this.currentLinkman.province][this.currentLinkman.city].code;
				let c =	TextToCode[this.currentLinkman.province][this.currentLinkman.city][this.currentLinkman.prefecture].code;
				this.locationSelectedOptions = [a,b,c];
			}
			this.addLinkmanFlag = true;
			this.isAddLinkman = false;
		},
		// 联系人设置组
		setLinkmanItem(obj){
			this.currentLinkmanGruop = obj;
			this.linkmanGruopArr = obj.groupName?obj.groupName.split(","):[];
			this.setLinkGroupFlag = true;
		},
		// 保存联系人设置分组
		saveLinkmanGroup(){
			const _this = this,
			linkmanId = _this.currentLinkmanGruop.id;
			let groupIds = [];
			_this.linkmanGroupList.forEach(i=>{
				if(_this.linkmanGruopArr.includes(i.name)){
					groupIds.push(i.id);
				}
			})
			groupIds = groupIds.length > 0?groupIds.join(','):'';
			post('/formadmin/linkman/set_group.jhtml',{linkmanId,groupIds}).then(res => {
				if(res.code == 200){
					_this.$message({
						type: 'success',
						message: '设置成功!',
						duration: 500,
						onClose(){
							_this.getEmailContactList();
							_this.getNoGroupLinkList();
							_this.setLinkGroupFlag = false;
						}
					});
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 删除联系人
		deleteLinkmanItem(obj){
			const _this = this;
			_this.$confirm('此操作将永久删联系人, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/linkman/linkman_delete.jhtml',{linkmanId: obj.id}).then(res => {
					if(res.code == 200){
						_this.$message({
							type: 'success',
							message: '删除成功!',
							duration: 500,
							onClose(){
								_this.getEmailContactList();
								_this.getNoGroupLinkList();
							}
						});
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {});
		},
		// 修改联系人分组
		editLinkgroupName(item){
			if(this.linkmanGroupList.some(i=>i.isEdit == true)){
				this.$message.warning('一次只能编辑一条数据哦！')
			}else{
				this.$set(item,'isEdit',true)
			}
		},
		// 更新联系人组
		sureEditLinkgroup(item){
			const _this = this;
			post('/formadmin/linkman/group_update.jhtml',{groupName: item.name,groupId: item.id}).then(res => {
				if(res.code == 200){
					_this.$message({
						type: 'success',
						message: '修改成功!',
						duration: 500,
						onClose(){
							_this.getEmailContactList();
							_this.getNoGroupLinkList();
						}
					});
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 保存联系人组
		sureAddLinkgroup(){
			const _this = this;
			if(!_this.newLinkgroupName){
				_this.$message.error('请输入分组名称！')
			}else{
				post('/formadmin/linkman/group_save.jhtml',{groupName: _this.newLinkgroupName}).then(res => {
					if(res.code == 200){
						_this.$message({
							type: 'success',
							message: '添加成功!',
							duration: 500,
							onClose(){
								_this.getEmailContactList();
								_this.getNoGroupLinkList();
								_this.addLinkGroupFlag = false;
							}
						});
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}
		},
		// 删除联系人组
		deleteLinkgroup(item){
			const _this = this;
			_this.$confirm('确定删除该联系人分组?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/linkman/group_delete.jhtml',{groupName: item.name,groupId: item.id}).then(res => {
					if(res.code == 200){
						_this.$message({
							type: 'success',
							message: '删除成功!',
							duration: 500,
							onClose(){
								_this.getEmailContactList();
								_this.getNoGroupLinkList();
							}
						});
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				_this.$message({
					type: 'info',
					message: '取消删除'
				});          
			});
		}
	}
};
</script>
<style lang="less" scoped>
	.share_set{
		width: 1100px;
		height: 100%;
		padding: 40px 0 20px;
		margin: 0 auto;
		position: relative;
		::v-deep .el-tabs{
			display: flex;
			flex-direction: column;
			height: 100%;
			.el-tabs__header{
				margin: 0;
				.el-tabs__nav-wrap{
					.el-tabs__nav-scroll{
						.el-tabs__nav{
							display: flex;
							.el-tabs__active-bar{
								display: none;
							}
							.el-tabs__item{
								display: flex;
								align-items: center;
								justify-content: center;
								width: 186px;
								height: 54px;
								padding: 0;
								font-size: 16px;
								&:hover{
									color: var(--theme-color);
								}
							}
							.is-active{
								background-color: #fff;
								color: var(--theme-color);
								border: 1px solid #eee;
								border-bottom: none;
							}
						}
					}
					&:after{
						display: none;
					}
				}
			}
			.el-tabs__content{
				flex: 1;
				background-color: #fff;
				border: 1px solid #eee;
				.el-tab-pane{
					height: 100%;
					.share-item{
						height: 100%;
						.el-scrollbar{
							height: 100%;
							.el-scrollbar__wrap{
								height: 100%;
								overflow-x: hidden;
							}
						}
					}
					.link-share{
						.el-scrollbar__view{
							padding: 0 60px;
							.top{
								padding: 0 0 10px;
								border-bottom: 1px solid #eee;
								.title{
									padding: 40px 0 20px;
									font-size: 16px;
								}
								.link-box{
									display: flex;
									justify-content: space-between;
									.copy{
										display: flex;
										.el-input{
											width: 500px;
											.el-input__inner{
												border-color: #e5e5e5;
												border-radius: 2px;
												color: #666;
											}
										}
										.el-button{
											width: 130px;
											height: 40px;
											margin: 0 0 0 10px;
											border-radius: 2px;
											border-color: var(--theme-color);
											color: var(--theme-color);
											&:hover{
												background-color: var(--theme-color);
												color: #fff;
											}
										}
									}
									.share-btns{
										display: flex;
										.el-button{
											width: 40px;
											height: 40px;
											padding: 0;
											margin: 0 0 0 10px;
											border-color: #e5e5e5;
											border-radius: 2px;
											.iconfont{
												font-size: 18px;
											}
											.icon-weixin{
												color: #66bf4c;
											}
											.icon-qq{
												color: #56b6e7;
											}
										}
									}
								}
							}
							.share-wechat{
								padding: 0 0 25px;
								.title{
									padding: 40px 0 25px;
									font-size: 16px;
								}
								.wechat-set{
									display: flex;
									.left{
										flex: 1;
										.title{
											font-size: 14px;
											line-height: 24px;
											padding: 15px 0 10px;
											&:first-child{
												padding-top: 0;
											}
										}
										.upload{
											display: flex;
											align-items: center;
											.btn{
												.upload-btn{
													.el-upload{
														display: flex;
														align-items: center;
														justify-content: center;
														width: 80px;
														height: 80px;
														border: 1px dashed #e5e5e5;
														margin: 0 15px 0 0;
														background-color: #f6f6f6;
														.img{
															width: 100%;
															height: 100%;
															object-fit: contain;
														}
														.el-icon-upload2{
															font-size: 26px;
															color: #bbb;
														}
													}
												}
											}
											.tips{
												line-height: 24px;
												.p1{
													color: #666;
												}
												.p2{
													font-size: 12px;
													color: #888;
												}
											}
										}
										.input-box{
											.el-input__inner{
												height: 34px;
												line-height: 34px;
												padding: 0 10px;
												border-radius: 2px;
												border-color: #e5e5e5;
												color: #666;
											}
											.el-textarea__inner{
												height: 130px;
												line-height: 24px;
												padding: 7px 10px;
												border-radius: 2px;
												border-color: #e5e5e5;
												color: #666;
											}
										}
										.c-tips{
											line-height: 24px;
											margin-top: 5px;
											font-size: 12px;
											color: #999;
										}
									}
									.line{
										width: 0;
										border-right: 1px dashed #eee;
										margin: 0 30px;
									}
									.right{
										flex: 1;
										overflow: hidden;
										.title{
											line-height: 24px;
											padding: 0 0 10px;
											font-size: 13px;
											color: #999;
										}
										.preview{
											display: flex;
											align-items: flex-start;
											padding: 30px 28px;
											background-color: #f7f7f7;
											.portrait{
												img{
													width: 40px;
													height: 40px;
												}
											}
											.cont{
												flex: 1;
												padding: 18px 20px;
												border: 1px solid #eee;
												background-color: #fff;
											}
										}
										.share-friend{
											.cont{
												margin: 0 15px 0 0;
												position: relative;
												font-size: 13px;
												overflow: hidden;
												.p-title{
													line-height: 20px;
													padding-bottom: 5px;
												}
												.p-desc{
													display: flex;
													align-items: flex-start;
													.txt{
														flex: 1;
														display: -webkit-box;
														-webkit-box-orient: vertical;
														-webkit-line-clamp: 2;
														overflow: hidden;
														line-height: 20px;
														margin: 0 15px 0 0;
														color: #999;
														word-break: break-all;
													}
													.img{
														width: 40px;
														height: 40px;
														object-fit: cover;
														border-radius: 2px;
													}
												}
												&:after{
													content: "";
													display: block;
													width: 10px;
													height: 10px;
													border-right: 1px solid #eee;
													border-top: 1px solid #eee;
													position: absolute;
													right: -7px;
													top: 15px;
													transform: rotate(45deg);
													background-color: #fff;
												}
											}
										}
										.share-circle{
											flex-direction: column;
											align-items: unset;
											.portrait{
												display: flex;
												align-items: center;
												p{
													margin: 0 0 0 15px;
													font-size: 13px;
												}
											}
											.cont{
												display: flex;
												align-items: center;
												padding: 10px;
												margin: 0 0 0 55px;
												img{
													width: 40px;
													height: 40px;
													margin: 0 15px 0 0;
													object-fit: cover;
													border-radius: 2px;
												}
											}
										}
									}
								}
							}
						}
						.submit-btn{
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 0 0 20px;
							.el-button{
								width: 100px;
							}
						}
					}
					.qrcode-share{
						.d-flex{
							display: flex;
							padding: 50px 36px;
							.style-f,.style-s{
								display: flex;
								flex-direction: column;
								align-items: center;
								padding: 32px 0 40px;
								border: 1px solid #eee;
								border-radius: 3px;
								text-align: center;
								.f-tit{
									line-height: 30px;
									font-size: 16px;
								}
								.s-tit{
									line-height: 30px;
									color: #999;
									margin: 0 0 42px;
								}
								.el-button{
									padding: 12px 30px;
									color: var(--theme-color);
									border-color: var(--theme-color);
									&:hover{
										color: #fff;
										background-color: var(--theme-color);
									}
								}
							}
							.style-f{
								width: 334px;
								.qrcode-box{
									width: 178px;
									height: 178px;
									border: 1px solid #e5e5e5;
									border-radius: 3px;
									margin: 0 0 58px;
								}
							}
							.style-s{
								flex: 1;
								margin: 0 0 0 16px;
								position: relative;
								.recommend{
									position: absolute;
									top: 0;
									left: 0;
								}
								.list{
									display: flex;
									margin: 0 0 46px;
									.item{
										img{
											border: 1px solid #e5e5e5;
											border-radius: 3px;
											margin: 0 10px;
										}
									}
								}
							}
						}
					}
					.email-share{
						display: flex;
						flex-direction: column;
						padding: 25px 50px;
						.top-btns{
							display: flex;
							justify-content: flex-end;
							padding: 0 0 15px;
							.el-button{
								height: 32px;
								line-height: 32px;
								padding: 0 10px;
								border-radius: 3px;
								border-color: var(--theme-color);
								color: var(--theme-color);
							}
						}
						.el-steps{
							padding-bottom: 23px;
							border-bottom: 1px solid #eee;
							.el-step.is-horizontal{
								.el-step__icon{
									width: 36px;
									height: 36px;
									border-width: 5px;
									border-color: #fff;
									background-color: #aaa;
									.el-step__icon-inner{
										font-weight: normal;
										color: #fff;
										font-size: 16px;
									}
								}
								.el-step__main{
									padding: 0 10px;
									position: absolute;
									white-space: nowrap;
									top: 0;
									left: 36px;
									background-color: #fff;
								}
								.el-step__line{
									height: 0;
									border-top: 1px dashed #e5e5e5;
									background: none;
									top: 18px;
								}
								.is-finish{
									.el-step__icon{
										background-color: var(--theme-color);
										border-color: #b2f0ff;
									}
									.el-step__line{
										border-color: var(--theme-color);
										.el-step__line-inner{
											display: none;
										}
									}
								}
								.el-step__title.is-finish{
									color: var(--theme-color);
								}
							}
							.el-step:last-of-type.is-flex{
								margin: 0 74px 0 0;
							}
						}
						.step-cont{
							height: 100%;
							overflow: hidden;
							.item{
								height: 100%;								
							}
							.add-receiver{
								display: flex;
								flex-direction: column;
								.tips{
									display: flex;
									align-items: center;
									justify-content: space-between;
									height: 40px;
									p{
										color: #999;
									}
									.el-button{
										padding: 0;
										border: none;
										background-color: transparent;
										color: var(--theme-color);
									}
								}
								.linkman{
									flex: 1;
									display: flex;
									border: 1px solid #e5e5e5;
									overflow: hidden;
									.left{
										display: flex;
										flex-direction: column;
										width: 270px;
										border-right: 1px solid #e5e5e5;
										.title{
											display: flex;
											align-items: center;
											justify-content: space-between;
											height: 36px;
											padding: 0 12px;
											border-bottom: 1px solid #e5e5e5;
											background-color: #efefef;
											.el-button{
												padding: 0;
												background-color: transparent;
												border: none;
												color: var(--theme-color);
											}
										}
										.search-box{
											padding: 10px;
											.el-input{
												.el-input__inner{
													height: 32px;
													padding: 0 30px 0 10px;
													border-color:#e5e5e5;
													border-radius: 2px;
												}
												.el-button{
													width: 24px;
													height: 32px;
													padding: 0;
													border: none;
													background-color: transparent;
													.el-icon-search{
														font-size: 16px;
														color: #bbb;
													}
													&:hover{
														.el-icon-search{
															color: var(--theme-color);
														}
													}
												}
											}
											

										}
										.linkman-group{
											flex: 1;
											overflow: hidden;
											.list{
												padding: 0 0 10px 15px;
												.item{
													.label{
														display: flex;
														align-items: center;
														height: 28px;
														font-size: 13px;
														margin-bottom: 4px;
														cursor: pointer;
														.iconfont{
															font-size: 16px;
															margin: 0 5px 0 0;
															color: #999;
														}
														.icon-zhankai3{
															display: none;
														}
														.icon-zhankai1{
															display: block;
														}
														&:hover{
															color: var(--theme-color);
															.iconfont{
																color: var(--theme-color);
															}
														}
													}
													.linkman-list{
														padding: 0 5px 0 10px;
														.linkman-item{
															display: flex;
															align-items: center;
															justify-content: space-between;
															height: 0;
															padding: 0 10px;
															font-size: 13px;
															color: #888;
															overflow: hidden;
															transition: height .2s;															
															.el-button{
																padding: 0;
																font-size: 13px;
																border: none;
															}
															&:hover{
																background-color: #f5f5f5;
															}
														}
														.linkman-item.disabled{
															.el-button{
																color: #bbb;
																cursor: not-allowed;
															}
														}
													}
													.no-data{
														display: flex;
														align-items: center;
														justify-content: center;
														height: 0;
														margin: 0 20px;
														font-size: 12px;
														color: #bbb;
														position: relative;
														transition: height .2s;
														overflow: hidden;	
														span{
															padding: 0 10px;
															position: relative;
															z-index: 2;
															background-color: #fff;
														}
														&::after{
															content: "";
															display: block;
															width: 100%;
															height: 1px;
															background-color: #ddd;
															position: absolute;
															top: 50%;
															left: 0;
														}
													}
												}
												.item.active{
													.icon-zhankai3{
														display: block;
													}
													.icon-zhankai1{
														display: none;
													}
													.linkman-item{
														height: 26px;
														transition: height .2s;
													}
													.no-data{
														height: 26px;
														transition: height .2s;
													}
												}
											}
										}
									}
									.right{
										flex: 1;
										.select-list{
											padding: 10px 20px 20px;
											.item{
												display: flex;
												align-items: center;
												justify-content: space-between;
												padding: 10px 5px;
												border-bottom: 1px solid #e5e5e5;
												.cont{
													font-size: 13px;
													color: #666;
													.el-icon-minus{
														margin: 0 5px;
													}
												}
												.el-button{
													padding: 0;
													border: none;
												}
											}
										}
									}
								}
								.foot{
									display: flex;
									align-items: center;
									justify-content: space-between;
									margin: 20px 0 0;
									.el-button{
										width: 88px;
										height: 34px;
										padding: 0;
									}
								}
							}
							.edit-content{
								display: flex;
								flex-direction: column;
								.tips{
									line-height: 26px;
									padding: 15px 0;
									color: #999;
								}
								.subject{
									display: flex;
									align-items: center;
									.label{
										width: 55px;
										line-height: 22px;
									}
									.el-input{
										flex: 1;
										.el-input__inner{
											height: 34px;
											padding: 0 5px;
											border-radius: 2px;
										}
									}
								}
								.c-editor{
									flex: 1;
									margin: 15px 0 0;
									.quill-editor{
										height: 100%;
										display: flex;
										flex-direction: column;
										.ql-toolbar.ql-snow{
											border-color: #DCDFE6;
										}
										.ql-container.ql-snow{
											border-color: #DCDFE6;
											.ql-editor{
												font-size: 14px;
											}
										}
									}
									
								}
								.foot{
									display: flex;
									justify-content: flex-end;
									.protocol{
										padding: 20px 0 10px;
										.el-checkbox__input.is-checked+.el-checkbox__label{
											color: #666;
										}
										.el-button{
											padding: 0;
											border: none;
										}
									}
									.btns{
										display: flex;
										justify-content: flex-end;
										margin: 20px 0 0;
										.el-button{
											padding: 9px 22px;
										}
									}
								}
							}
							.send-success{

							}
						}
					}
					.linkhtml-share{
						.el-scrollbar__view{
							padding: 0 60px;
							.top{
								padding: 0 0 10px;
								// border-bottom: 1px solid #eee;
								.title{
									padding: 40px 0 20px;
									font-size: 16px;
								}
								.link-box{
									display: flex;
									justify-content: space-between;
									.copy{
										display: flex;
										.el-input{
											width: 500px;
											.el-input__inner{
												border-color: #e5e5e5;
												border-radius: 2px;
												color: #666;
											}
										}
										.el-button{
											width: 130px;
											height: 40px;
											margin: 0 0 0 10px;
											border-radius: 2px;
											border-color: var(--theme-color);
											color: var(--theme-color);
											&:hover{
												background-color: var(--theme-color);
												color: #fff;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.make-qrcode-pop,
		.sent-emails-pop,
		.mailbox-set-pop,
		.linkman-list-pop,
		.add-linkman-pop{
			width: 100%;
			height: 100%;
			padding: 30px 0 20px;
			background-color: #f0f2f5;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 99;
			.title{
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 10px 0 0;
				font-size: 18px;
				span{
					display: flex;
					align-items: baseline;
					.num{
						display: flex;
						align-items: center;
						justify-content: center;
						width: 20px;
						height: 20px;
						margin: 0 0 0 5px;
						font-size: 12px;
						background-color: var(--theme-color);
						color: #fff;
						border-radius: 2px;
					}
				}
				
				.el-button{
					padding: 10px 20px;
				}
			}
			::v-deep .el-scrollbar{
				height: 100%;
				.el-scrollbar__wrap{
					overflow-x: hidden;
					.el-scrollbar__view{
						height: 100%;
						.poster-make{
							display: flex;
							background-color: #eee;
							border: 1px solid #eee;
							border-radius: 3px;
							margin: 17px 0 0;
							.left{
								.thumbnail_list{
									width: 90px;
									padding: 28px 0 20px;
									.item{
										width: 50px;
										margin: 0 auto 9px;
										text-align: center;
										cursor: pointer;
										.img{
											width: 32px;
											height: 52px;
											border:1px solid #dcdcdc;
											border-radius: 1px;
											margin:0 auto;
											box-shadow: 0 0 7px 0 rgba(151,151,151,0.22);
											background-color: #fff;
										}
										p{
											line-height: 24px;
											padding-top: 5px;
											font-size: 12px;
											color: #666;
										}
									}
									.item.active{
										.img{
											border-color: var(--theme-color);
											box-shadow: 0 0 7px 0 rgba(151,151,151,0.61);
										}
									}
									.style1{
										.img{
											background-image: url(../../assets/images/poster_type01.png);
										}
									}
									.style1.active{
										.img{
											background-image: url(../../assets/images/posterh_type01.png);
										}
									}
									.style2{
										.img{
											background-image: url(../../assets/images/poster_type02.png);
										}
									}
									.style2.active{
										.img{
											background-image: url(../../assets/images/posterh_type02.png);
										}
									}
									.style3{
										.img{
											background-image: url(../../assets/images/poster_type03.png);
										}
									}
									.style3.active{
										.img{
											background-image: url(../../assets/images/posterh_type03.png);
										}
									}
									.style4{
										.img{
											background-image: url(../../assets/images/poster_type04.png);
										}
									}
									.style4.active{
										.img{
											background-image: url(../../assets/images/posterh_type04.png);
										}
									}
									.style5{
										.img{
											background-image: url(../../assets/images/poster_type05.png);
										}
									}
									.style5.active{
										.img{
											background-image: url(../../assets/images/posterh_type05.png);
										}
									}
								}
							}
							.center{
								flex: 1;
								padding: 20px 30px;
								background-color: #fff;
								.c-title{
									display: flex;
									align-items: center;
									justify-content: space-between;
									line-height: 30px;
								}
								.upload-wrap{
									display: flex;
									justify-content: space-between;
									padding: 9px 0 16px;
									.upload-item{
										display: flex;
										align-items: center;
										.upload-btn{
											.el-upload{
												display: block;
												width: 100px;
												height: 100px;
												border: 1px dashed #e5e5e5;
												border-radius: 3px;
												margin: 0 15px 0 0;
												.upload-btn-ico{
													display: flex;
													flex-direction: column;
													align-items: center;
													justify-content: center;
													width: 100%;
													height: 100%;
													background-color: #f6f6f6;
													.el-icon-upload2{
														font-size: 20px;
														color: #bbb;
													}
													p{
														line-height: 24px;
														padding-top: 8px;
														font-size: 12px;
														color: #666;
													}
												}
												.img{
													width: 100%;
													height: 100%;
													object-fit: contain;
												}
											}
										}
										.tips{
											color: #666;
											.txt2{
												margin: 8px 0 0;
												font-size: 12px;
												color: #888;
											}
										}
									}
									.upload-item.disabled{
										opacity: .7;
										pointer-events: none;
									}
								}
								.recommend-bg-list{
									.item{
										float: left;
										width: calc(20% - 10px);
										padding: 4px 3px;
										border: 2px solid transparent;
										border-radius: 2px;
										margin: 4px 5px;
										cursor: pointer;
										position: relative;
										overflow: hidden;
										&:hover{
											border-color: var(--theme-color);
										}
										.bar{
											display: none;
											width: 50px;
											height: 30px;
											line-height: 44px;
											text-align: center;
											background-color: var(--theme-color);
											transform: rotate(45deg);
											position: absolute;
											top: -13px;
											right: -23px;
											.el-icon-check{
												color: #fff;
												font-size: 12px;
												font-weight: bold;
												transform: rotate(-33deg);
											}
										}
										img{
											width: 100%;
										}
									}
									.item.active{
										border-color: var(--theme-color);
										.bar{
											display: block;
										}
									}
								}
							}
							.right{
								width: 427px;
								.phone-box{
									width: 227px;
									height: 466px;
									padding: 14px 15px;
									margin: 48px auto 30px;
									position: relative;
									.phone-cover{
										width: 100%;
										height: 100%;
										position: absolute;
										top: 0;
										left: 0;
										img{
											width: 100%;
											height: 100%;
											object-fit: cover;
										}
									}
									.cus-bg{
										width: 100%;
										height: 100%;
										img{
											width: 100%;
											height: 100%;
											object-fit: cover;
										}
									}
									.img-dom{
										width: 100%;
										height: 100%;
										.poster-mian{
											position: absolute;
											top: 14px;
											bottom: 14px;
											left: 15px;
											right: 15px;
											.poster-code{
												line-height: 22px;
												padding: 5px 3px 2px;
												background-color: #fff;
												text-align: center;
												border-radius: 3px;
												position: absolute;
												p{
													line-height: 22px;
													font-size: 12px;
												}
											}
											.poster-title{
												line-height: 26px;
												color: #fff;
												font-size: 16px;
												position: absolute;
											}
										}
										.style-1{
											.poster-code{
												top: 30px;
												left: 15px;
											}
											.poster-title{
												left: 15px;
												bottom: 75px;
											}
										}
										.style-2{
											.poster-code{
												top: 70%;
												right: 15px;
											}
											.poster-title{
												top: 70%;
												left: 15px;
											}
										}
										.style-3{
											.poster-code{
												top: 45%;
												left: 50%;
												transform: translateX(-50%);
											}
											.poster-title{
												top: 30%;
												left: 50%;
												transform: translateX(-50%);
											}
										}
										.style-4{
											.poster-code{
												top: 20%;
												left: 50%;
												transform: translateX(-50%);
											}
											.poster-title{
												bottom: 75px;
												left: 15px;
											}
										}
										.style-5{
											.poster-code{
												bottom: 40px;
												right: 15px;
											}
											.poster-title{
												top: 50px;
												left: 50%;
												transform: translateX(-50%);
											}
										}
									}
								}
								.btns{
									display: flex;
									align-items: center;
									justify-content: center;
									padding: 0 0 30px;
									.el-button{
										width: 150px;
										height: 36px;
										padding: 0;
									}
								}
							}
						}
					}
				}
			}
		}
		.sent-emails-pop{
			display: flex;
			flex-direction: column;
			.sent-list{
				flex: 1;
				padding: 30px 20px;
				margin: 20px 0 0;
				background-color: #fff;
				::v-deep .el-table{
					.el-table__cell{
						padding: 8px 0;
						border-color: #e6e6e6;
					}
					th.el-table__cell{
						background-color: #f2f2f2;
						color: #333;
					}
				}
				.el-table--border,
				.el-table--group{
					border-color: #e6e6e6;
				}
				.el-table--border::after,
				.el-table--group::after,
				.el-table::before{
					background-color: #e6e6e6;
				}
			}
		}
		.mailbox-set-pop{
			display: flex;
			flex-direction: column;
			.set-list{
				flex: 1;
				padding: 20px;
				margin: 20px 0 0;
				background-color: #fff;
				overflow-y: auto;
				.el-form{
					margin: 20px 0 0;
					.el-form-item{
						.el-input,
						.el-select{
							width: 500px;
						}
						.el-icon-info{
							padding: 0;
							border: 0;
							margin: 0 0 0 10px;
							font-size: 20px;
							color: #bbb;
							vertical-align: -2px;
							&:hover{
								color: var(--theme-color);
							}
						}
						.el-button--primary{
							padding: 9px 30px;
							border-radius: 3px;
						}
						&:last-child{
							margin: 0;
						}
					}
				}
			}
		}
		.linkman-list-pop{
			display: flex;
			flex-direction: column;
			.linkman-main{
				flex: 1;
				padding: 20px;
				margin: 20px 0 0;
				background-color: #fff;
				.head{
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin: 0 0 15px;
					.search{
						display: flex;
						align-items: center;
						::v-deep .el-input{
							.el-input__inner{
								height: 34px;
								line-height: 34px;
								padding: 0 10px;
								border-radius: 2px;
							}
							.el-input__icon{
								line-height: 34px;
							}
						}
						.el-select{
							width: 150px;
						}
						.search-box{
							margin: 0 0 0 10px;
							width: 200px;
							.el-button{
								padding: 10px 5px;
								border: none;
								background-color: transparent;
							}
						}
					}
					.btns{
						display: flex;
						align-items: center;
						.el-dropdown{
							margin: 0 10px 0 0;
						}
						.el-button{
							padding: 8px 10px;
							border-radius: 2px;
							span{
								margin: 0 0 0 3px;
							}
						}
					}
					
					
				}
				::v-deep .el-table{
					.el-table__cell{
						padding: 8px 0;
						border-color: #e6e6e6;
						.operate{
							.el-button{
								padding: 0;
								border: none;
								background-color: transparent;
								font-size: 17px;
								color: #aaa;
							}
						}
					}
					th.el-table__cell{
						background-color: #f2f2f2;
						color: #333;
					}
				}
				.el-table--border,
				.el-table--group{
					border-color: #e6e6e6;
				}
				.el-table--border::after,
				.el-table--group::after,
				.el-table::before{
					background-color: #e6e6e6;
				}
			}
		}
		.add-linkman-pop{
			display: flex;
			flex-direction: column;
			.add-linkman{
				flex: 1;
				padding: 40px 100px 30px 30px;
				margin: 20px 0 0;
				background-color: #fff;
				overflow-y: auto;
				::v-deep .form-item{
					display: flex;
					.el-form-item{
						flex: 1;
						display: flex;
						align-items: flex-start;
						margin: 0 0 20px 0;
						.el-form-item__content{
							flex: 1;
							.el-select{
								width: 100%;
							}
							.el-date-editor.el-input{
								width: 100%;
								.el-input__inner{
									padding-left: 15px;
								}
								.el-input__prefix{
									left: auto;
									right: 10px;
								}
							}
							.el-cascader{
								width: 100%;
								margin: 0 0 10px;
							}
						}
						&::before,
						&::after{
							display: none;
						}
					}
				}
				::v-deep .form-btn{
					.el-form-item{
						margin: 0;
						.el-form-item__content{
							text-align: center;
							.el-button{
								padding: 10px 30px;
							}
						}
					}
				}
			}
		}
		.linkgroup-set-pop{
			::v-deep .el-dialog{
				width: 600px;
				.el-dialog__header{
					border-bottom: 1px solid #eee;
				}
				.el-dialog__body{
					padding: 20px 20px 10px;
					.add-btn{
						display: flex;
						justify-content: flex-end;
						.el-button{
							padding: 8px 10px;
						}
					}
					.list{
						height: 260px;
						border: 1px solid #eee;
						padding: 10px;
						margin: 15px 0 0;
						.el-scrollbar{
							height: 100%;
							.el-scrollbar__wrap{
								overflow-x: hidden;
								.el-scrollbar__view{
									height: 100%;
									.item{
										display: flex;
										align-items: center;
										justify-content: space-between;
										height: 40px;
										padding: 0 20px;
										background-color: #f8f8f8;
										border-radius: 3px;
										cursor: pointer;
										.el-input{
											width: 200px;
											.el-input__inner{
												height: 30px;
												padding: 0 10px;
												border-radius: 2px;
											}
										}
										.btn{
											.el-button{
												padding: 0;
												border: none;
												background-color: transparent;
												font-size: 16px;
												color: #999;
												&:hover{
													color: var(--theme-color);
												}
											}
										}
										.sure-btn{
											.el-button{
												padding: 5px 10px;
												font-size: 12px;
												border-radius: 3px;
											}
										}
										&:hover{
											background-color: #f5f5f5;
										}
									}
									.item+.item{
										margin: 10px 0 0;
									}
								}
							}
						}
					}
				}
				.el-dialog__footer{
					.el-button{
						width: 80px;
						padding: 10px 0;
					}
				}
			}
		}
		.set-linkgroup-pop{
			::v-deep .el-dialog{
				width: 600px;
				.el-dialog__header{
					border-bottom: 1px solid #eee;
				}
				.el-dialog__body{
					padding: 20px 20px 10px;
					.linkman-groups{
						height: 240px;
						background-color: #f8f8f8;
						border: 1px dashed #ddd;
						padding: 10px 20px;
						.el-checkbox{
							width: 33.33%;
							margin: 0 0 10px 0;
							.el-checkbox__input.is-checked+.el-checkbox__label{
								color: inherit;
							}
						}
					}
				}
				.el-dialog__footer{
					.el-button{
						width: 80px;
						padding: 10px 0;
					}
				}
			}
		}
	}
	.mail-tips{
		width: 300px;
		p{
			line-height: 1.4;
			margin: 0 0 10px;
			&:last-child{
				margin: 0;
			}
		}
	}
	.add-link-group{
			::v-deep .el-dialog{
				width: 400px;
				.el-dialog__header{
					border-bottom: 1px solid #eee;
				}
				.el-dialog__body{
					padding: 30px 20px 20px;
				}
				.el-dialog__footer{
					.el-button{
						width: 80px;
						padding: 10px 0;
					}
				}
			}
		}
	::v-deep .el-pagination{
		padding: 20px 10px 10px;
		font-weight: normal;
		text-align: right;
		.el-pagination__total,
		.el-pagination__sizes{
			float: left;
			.el-input{
				.el-input__inner{
					&:hover{
						border-color: var(--theme-color);
					}
				}
			}
		}
		.el-pager{
			li:not(.disabled){
				&:hover{
					color: var(--theme-color);
				}
			}
			li:not(.disabled).active{
				background-color: var(--theme-color);
				&:hover{
					color: #fff;
				}
			}
		}
		.btn-prev,
		.btn-next{
			font-size: 12px;
			padding: 0 5px;
		}
	}
</style>